<template>
  <div class="container">
    <div class="row align-items-center justify-content-center content-body">
      <div class="col-12 form-group card-step">
        <div class="card-body text-center">
          <div class="title-stepper mb-5">Whitelist Registration</div>
        </div>
        <div class="row align-items-start justify-content-center mb-3">
          <div class="col col-sm-5 col-md-5 col-lg-5 form-group input-group-lg">
            <input type="text" class="form-control" placeholder="First Name*" v-model="form.name"/>
            <error-message class="mt-1" :msg="errorMessage.name"></error-message>
          </div>
          <div class="col col-sm-4 col-md-4 col-lg-4 form-group input-group-lg">
            <input type="text" class="form-control" placeholder="Last Name*" v-model="form.surname"/>
            <error-message class="mt-1" :msg="errorMessage.surname"></error-message>
          </div>
        </div>
        <div class="row align-items-start justify-content-center mb-5">
          <div class="col-12 col-sm-9 form-group input-group-lg">
            <input type="text" class="form-control" placeholder="Email Address*" v-model="form.email"/>
            <error-message class="mt-1" :msg="errorMessage.email"></error-message>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mb-5">
          <div class="col-12 col-sm-9 d-grid gap-2 form-group text-center input-group-lg">
            <div class="btn btn-start btn-lg w-100" @click="register">
              I'm Ready
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validate from "validate.js";
import ErrorMessage from "../components/ErrorMessage";
import store from "@/store";
import Swal from "sweetalert2";
import Vue from "vue";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {GET_OTP, REGISTER, VERIFY_OTP} from "@/store/actions.type";
import {RecaptchaVerifier} from "firebase/auth";
import {auth} from "@/utils/firebase";

Vue.use(VueTelInput, {dropdownOptions: {showDialCodeInSelection: true}});

export default {
  name: "Register",
  components: {ErrorMessage},
  data() {
    return {
      form: {
        name: "",
        surname: "",
        email: "",
        ref: this.$route.query.ref || "",
        otp: "",
      },

      errorMessage: {},
      phoneObject: {},
    };
  },
  methods: {
    setMobileNo(event) {
      this.phoneObject = event;
    },

    async getOTP() {
    },
    async resendOTP() {
    },
    async register() {
      let errorMessage = this.validateForm(this.form);

      if (errorMessage) {
        return (this.errorMessage = errorMessage);
      } else {
        this.errorMessage = {};
      }

      if (this.isLoading) return;
      this.isLoading = true;

      let response = await store.dispatch(REGISTER, {
        name: this.form.name || "",
        surname: this.form.surname || "",
        mobile: this.form.mobile || "",
        email: this.form.email || "",
        ref: this.$route.query.ref || "",
        rpuid: this.$route.query.rpuid || "",
        partner: "growth_waitinglist",
      });
      this.isLoading = false;

      if (response.message === "success") {
        return Swal.fire({
          html: `<div>You're on the Waiting List</div>`,
          icon: "success",
          customClass: {
            confirmButton: "btn-primary",
          },
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

      } else if (response.message === "Duplicate entry" || response.message === "ER_DUP_ENTRY") {
        this.errorMessage = {};
        return (this.errorMessage.email = "email already used");
      } else {
        return Swal.fire({
          html: `<div>Please try again.</div>`,
          icon: "error",
          customClass: {
            confirmButton: "btn-primary",
          },
          allowOutsideClick: false,
          confirmButtonText: "OK",
        }).then(() => {
          this.isLoading = false;
        });
      }
    },
    validateForm(data) {
      let errMessageList = null;

      let validateRule = {
        name: {
          presence: {
            allowEmpty: false,
            message: "^Please enter first name",
          },
        },
        surname: {
          presence: {
            allowEmpty: false,
            message: "^Please enter last name",
          },
        },
        email: {
          presence: {
            allowEmpty: false,
            message: "^Please enter email address",
          },
          email: {
            message: "^Invalid email",
          },
        },
      };

      errMessageList = validate(
          {
            name: data.name,
            surname: data.surname,
            email: data.email
          },
          validateRule
      );

      if (errMessageList) {
        const error = {};
        for (const [key, value] of Object.entries(errMessageList)) {
          error[key] = value[0];
        }
        return error;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.btn-start {
  border-radius: 20px;
}
</style>
